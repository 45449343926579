<template>
  <div class="box">
    <div class="title">
      <div class="rule">
        <div v-if="this.$route.path!==('/profile')" id="back" class="fa fa-angle-left"
             @click="backToLogs"></div>
        <span class="name">{{ $t("singleLog.log") }} #{{ historyLog.id }}</span>
      </div>
    </div>
    <div class="body">
      <form autocomplete="off">
        <div class="input-field col">
          <input id="id" v-model="historyLog.id" disabled>
          <label for="id" class="active">{{ $t("singleLog.id") }}</label>
        </div>

        <div class="input-field col">
          <input id="user" v-model="historyLog.user" disabled>
          <label for="user" class="active">{{ $t("singleLog.user") }}</label>
        </div>

        <div class="input-field col">
          <input id="actionName" type="text" class="changeable" v-model="historyLog.actionName" disabled>
          <label for="actionName" class="active">{{ $t("singleLog.actionName") }}</label>
        </div>

        <div class="input-field col">
          <input id="message" type="text" class="changeable" v-model="historyLog.message" disabled>
          <label for="message" class="active">{{ $t("singleLog.message") }}</label>
        </div>

        <div class="input-field col">
          <!--          <input id="params" type="text" class="changeable" >-->
          <textarea class="active" v-model="historyLog.params" disabled></textarea>
          <!--          <label for="params" class="active">{{ $t("singleLog.params") }}</label>-->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Link from "../utils/Link";
import axios from "axios";

export default {
  data() {
    let data = {
      historyLog: {}
    }
    return this.initialization(data);
  },
  methods: {
    getLog() {
      let headers = Link.methods.getHeaders();
      return axios.get(Link.methods.getSingleHistoryLog(this.getId()), {headers}).then(res => {
        return res.data;
      })
    },
    getId() {
      return window.location.pathname.split('/')[2];
    },
    initialization(data) {
      this.getLog().then(d => {
        data.historyLog = d;
      });
      return data;
    },
    backToLogs() {
      return this.$router.push('/historyLogs');
    }
  }
}
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../../public/materialize-src/sass/materialize.scss';
@import '../../../public/styles/hints.scss';
@import "../../../public/styles/singleLog.scss";

textarea {
  width: 100%;
  height: 250px;
}
</style>